import Text from "../../Text";
import Container from "@/Components/Backend/Grid/Container";
import Row from "@/Components/Backend/Grid/Row";
import Column from "@/Components/Backend/Grid/Column";
import PinCards from "../PinCards/PinCards";
import { InvestmentDescriptionProps } from "@/types/components/front/blocks/investmentDescription";
import ClassicButton from "../../Buttons/ClassicButton";
import Carousel from "../Sliders/EstatesCarousel/EstatesCarousel";
import WebpPicture from "@/Components/WebpPicture";
import { useEffect, useState } from "react";
import IconHeadingSection from "../IconHeadingSection/IconHeadingSection";
import { Link } from "@inertiajs/react";
const InvestmentDescription = ({
    cards,
    sectionHeading,
    carouselSectionHeading,
    image,
    heading,
    button,
    contentLeft,
    contentRight,
    slides,
}: InvestmentDescriptionProps) => {

    return (
        <div className="mb-13">
            {sectionHeading && (
                <IconHeadingSection className="mb-6">
                    {sectionHeading}
                </IconHeadingSection>
            )}

            <div
                className="flex bg-cover bg-bottom bg-no-repeat md:h-[600px] h-[350px]"
                style={{ backgroundImage: `url(${image.src})`}}
            />
            {/* <WebpPicture className="w-full h-auto" src={image.src} alt={image.alt ? image.alt : ""} /> */}

            <div className=" bg-i-blue-light md:pb-10 sm:pb-10 pb-7">
                <Container>
                    <Row className="md:mb-26 mb-13">
                        <Column>
                            <IconHeadingSection className="mt-8 mb-6" withoutGrid>
                                {heading}
                            </IconHeadingSection>
                        </Column>
                        <Column>
                            <Row className=" justify-around">
                                <Column xl={5} lg={5} md={6}>
                                    {contentLeft.map((paragraph, index) => (
                                        <div key={index}>
                                            <Text
                                                className={`${!paragraph.list ? "mb-4" : "mb-2"
                                                    }`}
                                            >
                                                {paragraph.text}
                                            </Text>

                                            {paragraph.list && (
                                                <ul className="ml-4 list-disc">
                                                    {paragraph.list.map(
                                                        (item, index) => (
                                                            <li key={index}>
                                                                <Text>
                                                                    {item.text}
                                                                </Text>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            )}
                                        </div>
                                    ))}
                                </Column>
                                <Column xl={5} lg={5} md={6}>
                                    {contentRight.map((paragraph, index) => (
                                        <div key={index}>
                                            <Text
                                                className={`${!paragraph.list ? "mb-4" : "mb-2"
                                                    }`}
                                            >
                                                {paragraph.text}
                                            </Text>

                                            {paragraph.list && (
                                                <ul className="ml-4 list-disc">
                                                    {paragraph.list.map(
                                                        (item, index) => (
                                                            <li key={index}>
                                                                <Text>
                                                                    {item.text}
                                                                </Text>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            )}
                                        </div>
                                    ))}
                                </Column>
                            </Row>
                        </Column>
                        
                    </Row>
                </Container>
                
                {cards && (
                    <div className="xl:py-24 md:py-16 py-7">
                        <PinCards cards={cards} />
                    </div>
                )}

                {carouselSectionHeading && (
                    <IconHeadingSection className="mb-8">
                        {carouselSectionHeading}
                    </IconHeadingSection>
                )}

                <Carousel slides={slides} />
            </div>
        </div>
    );
};

export default InvestmentDescription;
