import Text from "../../Text";
import { LatestNewsProps } from "@/types/components/front/blocks/latestNews";
import Container from "@/Components/Backend/Grid/Container";
import Row from "@/Components/Backend/Grid/Row";
import Column from "@/Components/Backend/Grid/Column";
import moment from "moment";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "@inertiajs/react";
import WebpPicture from "@/Components/WebpPicture";
import IconHeadingSection from "../IconHeadingSection/IconHeadingSection";

const LatestNews = ({ news }: LatestNewsProps) => {
    const estateTagBackground: { [index: number]: string } = {
        5: "bg-i-blue",
        6: "bg-i-indigo",
        7: "bg-i-navy",
        8: "bg-i-gold",
        9: "bg-i-blue",
        10: "bg-i-indigo",
        11: "bg-i-blue",
    };

    return (
        <div className="md:py-[52px] py-7">
                <IconHeadingSection className="mb-6">
                    Aktualności
                </IconHeadingSection>
            <Container>
                <Row className="gap-y-8">
                    {news.map((el, id) => (
                        <Column key={el.title + id} xl={6} lg={6}>
                            <WebpPicture src={`/storage/news/${el.photo}`} alt="" className="lg:w-full sm:w-1/2 w-full object-cover object-center rounded h-[308px]" />

                            <div className="lg:w-full sm:w-1/2 w-full mt-4">
                                <div className="flex items-center justify-between my-1">
                                    <Text heading="label" bold>{moment(el.created_at).utc().format('DD.MM.YYYY')}</Text>

                                    <div className={`${estateTagBackground[el.get_estate.id]} py-1 px-3 rounded-[24px] text-center`}>
                                        <Text heading="label" bold color="white">{el.get_estate.name}</Text>
                                    </div>
                                </div>

                                <Text heading="h3" className="mb-2">{el.title}</Text>

                                <Text className="mb-1">{el.short_desc}</Text>

                                <Link href={route('front.news.single', el.slug)} className="flex justify-end items-center gap-2 flex-nowrap hover:text-i-blue">
                                    <Text heading="label" className=" hover:text-inherit transition-colors ease-in-out duration-300">przejdź do artykułu</Text>
                                    <Icon icon="material-symbols:arrow-right-alt-rounded" width={24} height={24} className="text-i-blue" />
                                </Link>
                            </div>
                        </Column>
                    ))}
                </Row>

                <Row className="justify-center">
                    <Column xl={5} lg={5} md={5}>
                        <Link title="Zobacz wszystkie aktualności" href={route('front.news')} className="mt-8 w-full inline-flex items-center px-4 py-3 bg-i-blue-to-indigo rounded-sm text-16 leading-6 font-bold text-i-light-gray text-center justify-center transition-all ease-in-out duration-300 gap-2 hover:opacity-80">
                            Zobacz pozostałe wpisy
                        </Link>
                    </Column>
                </Row>
            </Container>
        </div>
    );
};

export default LatestNews;
