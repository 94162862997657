import Text from "../../Text";
import Container from "@/Components/Backend/Grid/Container";
import Row from "@/Components/Backend/Grid/Row";
import Column from "@/Components/Backend/Grid/Column";
import { PinCardsProps } from "@/types/components/front/blocks/pinCards";

const PinCards = ({cards}:PinCardsProps) => {

    return (
        <Container>
            <Row className="justify-center">
                {cards.map((elem, index) => (
                    <Column xl={4} lg={4} md={6} sm={6} key={index}>
                        <div>
                            <img loading="lazy" className="mx-auto my-2 lg:h-[200px] md:h-40 h-32 object-contain object-center" src={elem.image.src} alt={elem.image.alt}/>
                        </div>

                        <Text className="mt-4 text-center">{elem.description}</Text>
                    </Column>
                ))}
            </Row>
        </Container>
    );
};

export default PinCards;
